<template>
  <div
    class="d-flex align-items-center"
    style="gap: 8px"
  >
    <div
      v-for="({icon, title, iconSize}, index) in quoteStatusBar"
      :key="index"
      class="d-flex align-items-center"
      style="gap: 8px"
    >
      <div
        class="d-flex align-items-center"
        style="gap: 8px"
      >
        <feather-icon
          :icon="icon"
          :size="iconSize"
          :class="checkItemStateAndStatus(item, index, orderStatesAndStatuses)"
        />
        <h3 :class="`text-secondary mb-0 font-weight-bolder ${checkItemStateAndStatus(item, index, orderStatesAndStatuses)}`">
          {{ $t(title) }}
        </h3>
      </div>
      <feather-icon
        v-if="index !== quoteStatusBar.length - 1"
        icon="LPolygonIconFill"
        size="18"
      />
    </div>
  </div>
</template>

<script>
import mainConfig from '@/views/main/warehouse/view/pack/config'

export default {
  name: 'QuoteStatusBar',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    quoteStatusBar: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { orderStatesAndStatuses, checkItemStateAndStatus } = mainConfig()

    return {
      orderStatesAndStatuses,
      checkItemStateAndStatus,
    }
  },
}
</script>
