import { TEXTAREA_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const MODULE_NAME = 'warehouse-orders'

  const tableColumns = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '3%' },
    },
    {
      key: 'state',
      label: 'State',
      thStyle: { width: '5%' },
    },
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '5%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '40%' },
    },
    {
      key: 'location',
      label: 'Location',
      thStyle: { width: '18%' },
    },
    {
      key: 'asset_id',
      label: 'Asset ID',
      thStyle: { width: '10%' },
    },
    {
      key: 'picked',
      label: 'Picked',
      thStyle: { width: '10%' },
    },
    {
      key: 'inspected',
      label: 'Inspected',
      thStyle: { width: '20%', marginRight: '50px' },
    },
  ]

  const quoteStatusBar = [
    {
      icon: 'LPickIcon',
      title: 'Pick',
      iconSize: '16',
    },
    {
      icon: 'LBoxIcon',
      title: 'Pack',
      iconSize: '16',
    },
    {
      icon: 'LTruckIcon',
      title: 'Dispatch',
      iconSize: '16',
    },
  ]

  const ORDERS_STATUS_NO_ACTION = Object.freeze({
    id: 'no_action',
    status: 0,
    title: 'To be done',
    style: {
      color: '#D9D9D9',
      styleName: 'ORDERS_STATUS_NO_ACTION',
    },
  })
  const ORDERS_STATUS_IN_PROGRESS = Object.freeze({
    id: 'in_progress',
    status: 1,
    title: 'In progress',
    style: {
      color: '#4E1476',
      styleName: 'ORDERS_STATUS_IN_PROGRESS',
    },
  })
  const ORDERS_STATUS_ATTENTION = Object.freeze({
    id: 'attention',
    status: 2,
    title: 'Attention!',
    style: {
      color: '#BB2124',
      styleName: 'ORDERS_STATUS_ATTENTION',
    },
  })
  const ORDERS_STATUS_WARNING = Object.freeze({
    id: 'warning',
    status: 3,
    title: 'Warning',
    style: {
      color: '#F0AD4E',
      styleName: 'ORDERS_STATUS_WARNING',
    },
  })
  const ORDERS_STATUS_READY = Object.freeze({
    id: 'ready',
    status: 4,
    title: 'Ready',
    style: {
      color: '#00B139',
      styleName: 'ORDERS_STATUS_READY',
    },
  })
  const ORDERS_STATUS_VOID = Object.freeze({
    id: 'void',
    status: 5,
    title: 'Void',
    style: {
      color: '#000000',
      styleName: 'ORDERS_STATUS_VOID',
    },
  })

  const orderStatesAndStatuses = [
    { ...ORDERS_STATUS_NO_ACTION },
    { ...ORDERS_STATUS_IN_PROGRESS },
    { ...ORDERS_STATUS_ATTENTION },
    { ...ORDERS_STATUS_WARNING },
    { ...ORDERS_STATUS_READY },
    { ...ORDERS_STATUS_VOID },
  ]

  function checkItemStateAndStatus(item, index, stateAndStatuses) {
    if (!item) {
      return 'ORDERS_STATUS_NO_ACTION'
    }
    // eslint-disable-next-line no-nested-ternary,no-undef
    return item.state === index
      ? `${stateAndStatuses.find(({ status }) => status === item.status).style.styleName}`
      : index < item.state ? 'ORDERS_STATUS_READY' : 'ORDERS_STATUS_NO_ACTION'
  }

  const warehousePackFields = {
    warehouseNotes: {
      type: TEXTAREA_INPUT,
      label: 'Warehouse Notes',
      placeholder: 'Write notes here...',
      options: {
        cols: '12',
      },
    },
    orderNotes: {
      type: TEXTAREA_INPUT,
      label: 'Order Notes',
      placeholder: 'User 1 @ Sep 10, 2022: Some order notes.',
      options: {
        cols: '12',
      },
    },
  }

  const packItemTableColumns = [
    {
      key: 'state',
      label: 'State',
      thStyle: { width: '20%' },
    },
    {
      key: 'id',
      label: 'ID',
      thStyle: { width: '30%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '50%' },
    },
  ]

  return {
    checkItemStateAndStatus,
    orderStatesAndStatuses,
    warehousePackFields,
    packItemTableColumns,
    quoteStatusBar,
    tableColumns,
    MODULE_NAME,
  }
}
