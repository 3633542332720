<template>
  <div>
    <portal to="body-top">
      <div>
        <h3 class="text-center font-weight-bolder mb-2">
          {{ `Fulfillment for Order ${'RO-8869'}` }}
        </h3>
      </div>
    </portal>
    <quote-status-bar
      :quote-status-bar="quoteStatusBar"
      :item="quote"
    />
    <div class="bg-white rounded p-1">
      <h3 class="font-weight-bolder font-medium-5">
        {{ 'Order Items' }}
      </h3>
      <div class="d-flex align-items-center">
        <feather-icon
          icon="LNotificationIcon"
          size="16"
        />
        <p
          style="margin: 0 0 0 5px;"
        >
          Picked finished. Please proceed with packing when able.
        </p>
      </div>
      <h1 class="font-medium-5 font-weight-bolder my-1">
        {{ 'Cameras + Lenses' }}
      </h1>
      <div>
        <l-table-list-collector
          ref="lTableRef"
          :is-searchable="false"
          :module-name="MODULE_NAME"
          :table-columns="tableColumns"
          :fetched-data="pickedItemsArray"
        >
          <template #head(actions)="{data}">
            <b-form-checkbox />
          </template>
          <template #cell(actions)>
            <span class="d-block ml-1">
              <b-form-checkbox />
            </span>
          </template>
          <template #cell(state)>
            <span class="d-block text-center">
              <feather-icon
                icon="LPickIcon"
                size="18"
                style="color: #00B139"
              />
            </span>
          </template>
          <template #cell(picked)="{data}">
            <span class="d-block ml-3">
              <b-form-checkbox />
            </span>
          </template>
          <template #cell(inspected)="{data}">
            <span class="d-block text-center ml-4">
              <b-form-checkbox />
            </span>
          </template>
        </l-table-list-collector>
        <l-table-list-collector
          :is-searchable="false"
          :module-name="MODULE_NAME"
          :table-columns="tableColumns"
          :fetched-data="pickedItemsArray"
          :is-footer="false"
        />
        <div class="d-flex align-items-center justify-content-between">
          <span class="font-weight-bolder">{{ $t('Total Items') }}</span>
          <span
            style="width: 300px; display: block"
            class="d-flex justify-content-around font-weight-bolder"
          >
            <span>8 of 8</span>
            <span>8 of 8</span>
          </span>
        </div>
      </div>
    </div>
    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <div
          class="d-flex"
          style="gap: 8px"
        >
          <b-button
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
          >
            {{ $t('Back to List') }}
          </b-button>
          <b-button
            disabled
            class="px-3 font-medium-1 font-weight-bolder"
            variant="secondary"
          >
            {{ $t('Request Split Order') }}
          </b-button>
        </div>
        <div
          class="d-flex"
          style="gap: 8px"
        >
          <b-button
            variant="secondary"
            class="font-medium-1 font-weight-bold px-3"
          >
            <feather-icon icon="LPrintIcon" />
            {{ $t('Print Pick List') }}
          </b-button>
          <b-button
            variant="secondary"
            class="font-medium-1 font-weight-bold px-3"
            @click="navigateTo"
          >
            <feather-icon icon="LBoxIcon" />
            {{ $t('Pack') }}
          </b-button>
        </div>
      </div>
    </portal>
  </div>
</template>
<script>
import { BFormCheckbox, BButton } from 'bootstrap-vue'
import QuoteStatusBar from '@/views/main/warehouse/view/pack/components/use-as-global/QuoteStatusBar.vue'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import config from '../config'

export default {
  components: {
    LTableListCollector, QuoteStatusBar, BFormCheckbox, BButton,
  },
  data() {
    return {
      pickedItemsArray: [
        {
          name: 'FUJIFILM X-H2S Mirrorless',
          sku: 'FF0001',
          location: 'F1-A3-R2-SA',
          asset_id: '63453544',
        },
        {
          name: 'FUJIFILM X-H2S Mirrorless',
          sku: 'FF0001',
          location: 'F1-A3-R2-SA',
          asset_id: '63453544',
        },
        {
          name: 'FUJIFILM X-H2S Mirrorless',
          sku: 'FF0001',
          location: 'F1-A3-R2-SA',
          asset_id: '63453544',
        },
        {
          name: 'FUJIFILM X-H2S Mirrorless',
          sku: 'FF0001',
          location: 'F1-A3-R2-SA',
          asset_id: '63453544',
        }, {
          name: 'FUJIFILM X-H2S Mirrorless',
          sku: 'FF0001',
          location: 'F1-A3-R2-SA',
          asset_id: '63453544',
        },

      ],
    }
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
  },
  methods: {
    navigateTo() {
      this.$router.push({ name: 'home-warehouse-packed-items' })
    },
  },
  setup() {
    const { MODULE_NAME, tableColumns, quoteStatusBar } = config()

    return {
      quoteStatusBar,
      MODULE_NAME,
      tableColumns,
    }
  },
}
</script>
